import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import notFoundImg from "../images/common/404.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="not-found-pg">
      <img
        className="not-found-img"
        src={notFoundImg}
        alt="404 error - not found"
      />
      <h1>Page Not Found</h1>
      <p>This page doesn't exist.</p>
      <p>
        You can navigate via the <Link to="/">Home</Link> page
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
